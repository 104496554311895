export default {
    // api: 'https://backend-spider-kimberly.grow-corporate.com/',
    // api: 'https://the-brain-backend.softys-leadcorporate.com/',
    api: 'https://backend-spider-cl.grow-corporate.com/',
    // api: 'https://backend-spider-kimberly-corporativa.grow-corporate.com/',
    basename: '/',
    nombreSistema : 'Demo',
    nombreSistemaHo : 'Demo',
    nombreCliente : 'Grow'
};
